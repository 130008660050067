import {ErrorHandler, Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {ToastService} from "src/app/services/toast/toast.service";
import {ApolloError} from "@apollo/client/errors";
import {GraphQLError} from "graphql/error/GraphQLError";
import {Observable, throwError} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";
// eslint-disable-next-line no-restricted-imports
import {MissingPermissionException} from "../../models/utils/error";

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService implements ErrorHandler {

  constructor(
    private t: TranslateService,
    private toast: ToastService,
  ) {
  }


  handleError(error: HttpErrorResponse | ApolloError): Observable<never> {
    if (error instanceof ApolloError) {
      if (error.networkError && (error.networkError as HttpErrorResponse).error?.errors) {
        this.handleGraphQlErrors((error.networkError as HttpErrorResponse).error.errors)
      }
      this.handleHttpError((error.networkError as HttpErrorResponse));
    } else {
      this.handleHttpError(error);
    }

    return throwError(() => error);
  }

  private handleHttpError(e: HttpErrorResponse) {
    if (e.status === 401) {
      this.toast.error(this.t.instant('common.error'), this.t.instant('error.unauthorized'));
      return;
    }

    if (e.status === 403) {
      if (e.error && (e.error as MissingPermissionException).permissions) {
        const missingPermissions = (e.error as MissingPermissionException).permissions;
        this.toast.error(this.t.instant('common.error'), this.t.instant('error.missing_permissions', {permissions: missingPermissions.join(', ')}));
        return;
      }
      this.toast.error(this.t.instant('common.error'), this.t.instant('error.permission_denied'));
      return;
    }

    this.toast.error(this.t.instant('common.error'), e.message);
    console.error(e);
  }

  private handleGraphQlErrors(errors: GraphQLError[]) {
    errors.forEach((e: GraphQLError) => {
      this.toast.error(this.t.instant('common.api_error'), `${e.message}${e.path ? ' ' + e.path.join('.') : ''}`);
    });
  }

}

